<template>
  <div class="admin-container">
    <template>
      <v-row>
        <v-col cols="12" md="12">
          <v-btn
            tile
            small
            @click="
              $router.push({
                name: 'Contracts',
              })
            "
            >Zpět</v-btn
          >
        </v-col>
      </v-row>
      <h3 class="mt-5 mb-8 col-secodary-2">Smlouva</h3>
      <v-row>
        <v-col cols="12" md="3"
          ><v-text-field
            v-model="data.name"
            label="Název smlouvy *"
          ></v-text-field
        ></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-textarea
            v-model="data.description"
            label="Poznámka"
            auto-grow
          ></v-textarea>
        </v-col>
      </v-row>

      <input
        style="display: none"
        ref="documentFile"
        type="file"
        class="input--file"
        multiple
        @input="handleInputFile($event.target.files)"
      />
      <div
        class="d-flex flex-column flex-md-row align-center align-md-start doc-wrapper"
      >
        <div
          v-for="(document, documentIdx) in documents"
          :key="document.document_id"
        >
          <div class="document-added mr-md-6">
            <div
              class="document-document-mini"
              :style="correctIcon(document)"
            ></div>
            <div class="document-extension">
              {{ document.extension ? document.extension.toUpperCase() : '' }}
            </div>
            <!-- <div class="document-text">
              Dokument <br />{{ document.mimetype }}
            </div> -->
            <div class="document-close">
              <button
                @click="handleDeleteFile(document.document_id, documentIdx)"
              >
                <img :src="require('@/assets/zavrit.svg')" alt="" />
              </button>
            </div>
          </div>
          <v-text-field
            style="width: 150px"
            v-model="document.name"
            label="Název souboru *"
          ></v-text-field>
        </div>

        <button class="document-input-add" @click="handleClick('documentFile')">
          <div class="document-input-add-plus">+</div>
          <div class="document-input-add-text">Vložit dokument</div>
        </button>
      </div>
      <v-row>
        <v-col cols="12" md="3"></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3"></v-col>
      </v-row>
      <v-row class="mt-8">
        <v-col class="text-right">
          <v-btn
            small
            tile
            color="secondary"
            class="mr-4"
            @click="
              $router.push({
                name: 'Contracts',
              })
            "
            >Zrušit</v-btn
          >
          <v-btn small tile color="primary" @click="store">Uložit</v-btn>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import bus from '../plugins/bus';
import authHandler from '../api/authHandler';
import permissionDenied from '../api/permissionDenied';
import errorHandler from '../api/errorHandler';
import documentService from '../api/documentService';
import contractService from '../api/contractService';
const IMAGES_EXT = ['jpg', 'jpeg', 'svg', 'png'];
const PDF = 'pdf';
const ZIP = 'zip';
const PPT = 'ppt';
const XLS = 'xls';

export default {
  data() {
    return {
      data: {},
      documents: [],
    };
  },
  async created() {
    permissionDenied(
      this.user,
      this.$router,
      'SUPERUSER',
      'PROJECT_MANAGER',
      'MAIN_SUPERUSER',
      'MAIN_PROJECT_MANAGER'
    );
    bus.$emit('processing');
    bus.$emit('processing', false);
  },
  methods: {
    correctIcon(doc) {
      let icon = 'document.svg';
      if (IMAGES_EXT.includes(doc.extension)) icon = 'jpg.svg';
      if (PDF == doc.extension) icon = 'pdf.svg';
      if (doc.extension.includes(ZIP)) icon = 'zip.svg';
      if (doc.extension.includes(PPT)) icon = 'ppt.svg';
      if (doc.extension.includes(XLS)) icon = 'xls.svg';
      return {
        backgroundImage: `url(${require('@/assets/' + icon)})`,
      };
    },
    async handleDeleteFile(documentId, documentIdx) {
      try {
        bus.$emit('processing');
        await documentService._delete(documentId);
        this.documents.splice(documentIdx, 1);
        bus.$emit('processing', false);
      } catch (error) {
        bus.$emit('processing', false);
        console.error(error);
        errorHandler(error);
      }
    },
    handleClick(ref) {
      this.$refs[ref].click();
    },
    async handleInputFile(f) {
      try {
        console.debug(f);
        bus.$emit('processing');
        for (let fidx = 0; fidx < f.length; fidx++) {
          const { data } = await documentService.store(f[fidx]);
          this.documents.push({
            document_id: data.id,
            name: data.client_name,
            mimetype: data.mimetype,
            mime: data.mime,
            extension: data.extension,
          });
        }
        bus.$emit('processing', false);
        bus.$emit('snackbar', {
          text: `Podařilo se nahrát dokumenty`,
          color: 'info',
        });
        this.$refs.documentFile.values = [];
      } catch (error) {
        bus.$emit('processing', false);
        console.error(error);
        errorHandler(error);
      }
    },
    async indexTimelines() {
      try {
        const data = await timelineService.index();
        this.timelines = data;
      } catch (error) {
        console.error(error);
        errorHandler(error);
      }
    },
    async store() {
      try {
        bus.$emit('processing');
        const response = await contractService.store({
          ...this.data,
          documents: this.documents,
        });
        console.debug('Response', response);
        bus.$emit('processing', false);
        bus.$emit('snackbar', {
          text: 'Uloženo',
          color: 'success',
        });
        this.$router.push({
          name: 'Contracts',
        });
      } catch (error) {
        console.error(error);
        bus.$emit('processing', false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit('snackbar', {
            text: error.response.data.error.message,
            color: 'error',
          });
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  watch: {},
};
</script>

<style></style>
